//************************  React import   ************************ //
import React, { useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Heading, Wrapper } from "../../components";
import Inputs from "../../components/Inputs";
import MultiSelect from "../../components/MultiSelect";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";
import pageHoc from "../../features/accessibility/hoc/PageHoc";

//************************  Matrial UI import   ************************ //
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

//************************ Constant Data import   ************************ //
import { CONSULTDEP, CONSULTS, GETOFFICE } from "../../data/apis";
import { ADMIN, ALLACCESS, OWNER, USER } from "../../data/semantics";

//************************  Assets import   ************************ //
import backgroundImage from "../../assets/Betngan.png";

//************************  Library import   ************************ //
// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

///////////////////////////////////////////////////////////////////////////////////////////

const AddSingleConsultancy = () => {
  //************************  Hooks   ************************ //
  // Save consalt department data
  const [consultdep, setConsultdepRequest] = useState([]);
  // Save offices data
  const [offices, setOffices] = useState([]);

  // get consult type from location
  const { state } = useLocation();

  // Navigate
  const navigate = useNavigate();

  ////////////////////// Files selection ///////////////////

  // File One
  const [selectedFile1, setSelectedFile1] = useState("");
  const [previewFile1, setPreviewFile1] = useState("");
  const inputFile1 = useRef();
  ////////////////////////

  // File Two
  const [selectedFile2, setSelectedFile2] = useState("");
  const [previewFile2, setPreviewFile2] = useState("");
  const inputFile2 = useRef();
  ////////////////////////

  // File Three
  const [selectedFile3, setSelectedFile3] = useState("");
  const [previewFile3, setPreviewFile3] = useState("");
  const inputFile3 = useRef();
  ////////////////////////

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const addConsultancieLang = useSelector(
    (state) => state.lang.value.addConsultancie
  );
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Get consalt department data
  const [consultdepRequest, consultdepData] = useFetch({
    url: CONSULTDEP,
    method: "get",
  });

  // Get offices data
  const [officeRequest, officeData] = useFetch({
    url: GETOFFICE,
    method: "get",
  });

  // Send data to server
  const [consultRequest, consultData] = useFetch({
    url: CONSULTS,
    method: "post",
  });

  //*************************************************************** //

  //************************  Js Functions Code ************************ //

  // consultance type multi selecte Open function
  const handleConsultanceOpen = () => {
    consultdepRequest({
      onSuccess: (res) => {
        console.log(res.data);
        setConsultdepRequest(res.data);
      },
    });
  };

  // Offece multi selecte Open function
  const handleOpen = () => {
    if (values.department) {
      officeRequest({
        params: state.name ? { id: state.id } : { id: values.department },
        onSuccess: (res) => {
          setOffices(res.data);
        },
      });
    }
  };

  // Navigate to consultancies page
  /*const handleGoOut = () => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/consultancies`);
  };*/

  //*************************************************************** //

  //************************  Form Js Code ************************ //

  // Validation Schema using Yup
  const addSingleConsultanciesSchema = Yup.object({
    department: Yup.string().required("Department required"),
    receiver: Yup.string().required("Office required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("consultancy Text required"),
  });

  // set initial, validate, Get, and submit form data using formik library and send request to server
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: {
      department: state.name ? state.name : "",
      receiver: "",
      title: "",
      timezone: "",
      description: "",
    },

    validationSchema: addSingleConsultanciesSchema,
    onSubmit: () => {
      values.department = state.name
        ? (values.department = state.id)
        : values.department;
      // set time zone

      const formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        formData.append(key, value);
      });
      formData && formData.delete("timezone");
      formData &&
        formData.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );

      formData && formData.append("file_one", selectedFile1);
      formData && formData.append("file_two", selectedFile2);
      formData && formData.append("file_three", selectedFile3);

      consultRequest({
        onSuccess: (res) => {
          handleReset();

          // Delete Selected Files
          //File One
          setPreviewFile1("");
          setSelectedFile1("");
          inputFile1.current.value = "";

          //File Two
          setPreviewFile2("");
          setSelectedFile2("");
          inputFile2.current.value = "";
          //File Three
          setPreviewFile3("");
          setSelectedFile3("");
          inputFile3.current.value = "";
          /////////////////////

          // handleGoOut();
        },
        body: formData,
        onFail: (err) => {
          console.log(err);
        },
      });
    },
  });

  //*************************************************************** //
console.log(offices);
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: {
          xs: "100vh",
          sm: "100vh",
          md: "100vh",
          lg: "100vh",
          xl: "100vh",
        },

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Wrapper>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
          sx={{
            paddingTop: {
              xs: 15,
              sm: 15,
              md: 15,
              lg: 15,
              xl: 15,
            },

            paddingBottom: {
              xs: 5,
              sm: 5,
              md: 5,
            },
          }}
        >
          <Heading sx={{ fontSize: "3rem" }}>
            {global.addConsultancie[lang]}
          </Heading>

          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              gap: "2rem",
            }}
          >
            {state.name ? (
              <Inputs
                id={"title"}
                variant={"standard"}
                name={"title"}
                placeholder={addConsultancieLang.consultancieType[lang]}
                type={"input"}
                value={values.department}
                width={"80%"}
              />
            ) : (
              <MultiSelect
                placeholder={addConsultancieLang.consultancieType[lang]}
                sx={{ width: "80%" }}
                value={values.department}
                isPending={consultdepData.isPending}
                multiple={false}
                onChange={(e) => setFieldValue("department", e.target.value)}
                onOpen={handleConsultanceOpen}
                renderValue={(selected) => {
                  return consultdep.results?.find((item) => item.id === selected)?.name;
                }}
                error={errors.department}
              >
                {consultdep?.results?.map((item, index) => (
                  <MenuItem value={item.id} key={`item ${index}`}>
                    {item?.name}
                  </MenuItem>
                ))}
              </MultiSelect>
            )}

            <MultiSelect
              placeholder={addConsultancieLang.office[lang]}
              sx={{ width: "80%" }}
              value={values.receiver}
              isPending={officeData.isPending}
              multiple={false}
              onChange={(e) => setFieldValue("receiver", e.target.value)}
              onOpen={handleOpen}
              error={errors.receiver}
              renderValue={(selected) => {
                return offices.results?.find((item) => item.id === selected)
                  ?.office_name;
              }}
            >
              {offices?.results?.length > 0 &&
              offices.results?.map((item, index) => (
                <MenuItem value={item.id} key={`item ${index}`}>
                  {item.office_name}
                </MenuItem>
              ))}
            </MultiSelect>

            <Inputs
              id={"title"}
              variant={"standard"}
              name={"title"}
              placeholder={addConsultancieLang.leaveConsultancie[lang]}
              type={"input"}
              onChange={handleChange}
              value={values.title}
              onBlur={handleBlur}
              error={touched.title && Boolean(errors.title)}
              helperText={touched.title && errors.title}
              width={"80%"}
            />

            <Inputs
              id={"description"}
              variant={"standard"}
              name={"description"}
              placeholder={addConsultancieLang.consultancieDetails[lang]}
              multiline={true}
              rows={5}
              type={"textarea"}
              onChange={handleChange}
              value={values.description}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              width={"80%"}
            />

            {/* File One */}
            <Box sx={{ width: "50%", height: "10vh", marginBlock: 1 }}>
              <Box
                component={"input"}
                type="file"
                ref={inputFile1}
                name="file1"
                sx={{
                  display: "none",
                }}
                onChange={(event) => {
                  const previewFile1 = URL.createObjectURL(
                    event.target.files[0]
                  );
                  setPreviewFile1(previewFile1);
                  setSelectedFile1(event.target.files[0]);
                }}
              />
              <Box
                sx={{
                  minHeight: "100%",
                  maxHeight: "100%",
                  // maxHeight: "5rem",
                  width: "100%",
                  display: "flex",
                  // alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid white",
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "30%",
                      xl: "30%",
                    },
                    // minHeight: "90%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    inputFile1.current.click();
                  }}
                >
                  <AddIcon sx={{ width: "2rem", height: "2rem" }} />
                  <Typography sx={{ fontWeight: "700" }}>
                    {addConsultancieLang.addFile[lang]}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "70%",
                    minHeight: "100%",
                  }}
                >
                  {previewFile1 ? (
                    <Box
                      sx={{
                        position: "relative",
                        height: "100%",
                        border: "1px solid #fff",
                      }}
                    >
                      <Box
                        sx={{
                          // maxWidth: "50px",
                          height: "100%",
                          // bgcolor: "#fff",
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#e05436",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          variant="p"
                          sx={{
                            overflow: "hidden",
                            display: "inline-block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            // height: "1.2em",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {selectedFile1.name}
                        </Box>
                      </Box>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          fontSize: "2rem",
                          cursor: "pointer",
                          position: "absolute",
                          top: "0",
                          right: "0",
                          // "&:hover": {
                          //   color: "red",
                          //   transition: "all 0.2s",
                          // },
                        }}
                        onClick={() => {
                          setPreviewFile1("");
                          setSelectedFile1("");
                          inputFile1.current.value = "";
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      {addConsultancieLang.filePreview[lang]}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            {/* File Two */}
            <Box sx={{ width: "50%", height: "10vh", marginBlock: 1 }}>
              <Box
                component={"input"}
                type="file"
                ref={inputFile2}
                name="file2"
                sx={{
                  display: "none",
                }}
                onChange={(event) => {
                  const previewFile2 = URL.createObjectURL(
                    event.target.files[0]
                  );
                  setPreviewFile2(previewFile2);
                  setSelectedFile2(event.target.files[0]);
                }}
              />
              <Box
                sx={{
                  minHeight: "100%",
                  maxHeight: "100%",
                  // maxHeight: "5rem",
                  width: "100%",
                  display: "flex",
                  // alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid white",
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "30%",
                      xl: "30%",
                    },
                    // minHeight: "90%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    inputFile2.current.click();
                  }}
                >
                  <AddIcon sx={{ width: "2rem", height: "2rem" }} />
                  <Typography sx={{ fontWeight: "700" }}>
                    {addConsultancieLang.addFile[lang]}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "70%",
                    minHeight: "100%",
                  }}
                >
                  {previewFile2 ? (
                    <Box
                      sx={{
                        position: "relative",
                        height: "100%",
                        border: "1px solid #fff",
                      }}
                    >
                      <Box
                        sx={{
                          // maxWidth: "50px",
                          height: "100%",
                          // bgcolor: "#fff",
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#e05436",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          variant="p"
                          sx={{
                            overflow: "hidden",
                            display: "inline-block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            // height: "1.2em",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {selectedFile2.name}
                        </Box>
                      </Box>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          fontSize: "2rem",
                          cursor: "pointer",
                          position: "absolute",
                          top: "0",
                          right: "0",
                          // "&:hover": {
                          //   color: "red",
                          //   transition: "all 0.2s",
                          // },
                        }}
                        onClick={() => {
                          setPreviewFile2("");
                          setSelectedFile2("");
                          inputFile2.current.value = "";
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      {addConsultancieLang.filePreview[lang]}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            {/* File Three */}
            <Box sx={{ width: "50%", height: "10vh", marginBlock: 1 }}>
              <Box
                component={"input"}
                type="file"
                ref={inputFile3}
                name="file3"
                sx={{
                  display: "none",
                }}
                onChange={(event) => {
                  const previewFile3 = URL.createObjectURL(
                    event.target.files[0]
                  );
                  setPreviewFile3(previewFile3);
                  setSelectedFile3(event.target.files[0]);
                }}
              />
              <Box
                sx={{
                  minHeight: "100%",
                  maxHeight: "100%",
                  // maxHeight: "5rem",
                  width: "100%",
                  display: "flex",
                  // alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid white",
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "30%",
                      xl: "30%",
                    },
                    // minHeight: "90%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    inputFile3.current.click();
                  }}
                >
                  <AddIcon sx={{ width: "2rem", height: "2rem" }} />
                  <Typography sx={{ fontWeight: "700" }}>
                    {addConsultancieLang.addFile[lang]}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "70%",
                    minHeight: "100%",
                  }}
                >
                  {previewFile3 ? (
                    <Box
                      sx={{
                        position: "relative",
                        height: "100%",
                        border: "1px solid #fff",
                      }}
                    >
                      <Box
                        sx={{
                          // maxWidth: "50px",
                          height: "100%",
                          // bgcolor: "#fff",
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#e05436",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          variant="p"
                          sx={{
                            overflow: "hidden",
                            display: "inline-block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "100%",
                            // height: "1.2em",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {selectedFile3.name}
                        </Box>
                      </Box>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          fontSize: "2rem",
                          cursor: "pointer",
                          position: "absolute",
                          top: "0",
                          right: "0",
                          // "&:hover": {
                          //   color: "red",
                          //   transition: "all 0.2s",
                          // },
                        }}
                        onClick={() => {
                          setPreviewFile3("");
                          setSelectedFile3("");
                          inputFile3.current.value = "";
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      {addConsultancieLang.filePreview[lang]}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            {consultData.isPending ? (
              <CircularProgress />
            ) : (
              <Button
                variant="lg"
                sx={{ width: "20rem" }}
                type="submit"
                disabled={consultData.isPending}
              >
                {global.send[lang]}
              </Button>
            )}
          </Box>
        </Stack>
        {consultData.successAlert}
        {consultData.failAlert}
      </Wrapper>
    </Box>
  );
};

export default pageHoc(AddSingleConsultancy, [ADMIN, OWNER, USER], "/login");
