//************************  React import   ************************ //
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Wrapper, TenderCard } from "../../../components";
import Heading from "../../../components/Heading";

//************************ Data import   ************************ //
import { TENDERCARD } from "../../../data/apis";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const Tenders = () => {
  //************************  Hooks   ************************ //
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from redux
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);

  // Get tenders from redux
  const tendersStore = useSelector((state) => state.home.value.tenders);

  //*************************************************************** //

  //************************  API Request   ************************ //
  const [request, data] = useFetch({ url: TENDERCARD, method: "get" });

  //*************************************************************** //

  //************************  useEffect ************************ //
  useEffect(() => {
    request({
      params: { page_size: 3 },
      onSuccess: (res) => {
        dispatch({ type: "home/setTender", payload: res?.data });
      },
    });
  }, []);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //

  // navigate to specific tender page
  const handleAddTenders = (_, id) => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/tenders/${id}`);
  };

  // navigate to all tenders page
  const handelAllTenders = () => {
    window.scrollTo({ top: 0 });
    navigate("/tenders");
  };

  //*************************************************************** //

  return (
    <Box className="global-padding-y" id="tenders">
      <Wrapper>
        <Stack spacing={4} alignItems={"center"}>
          <Heading>{global.tender[lang]}</Heading>
          <Stack>
            <Grid container spacing={4}>
              {data.isPending ? (
                <CircularProgress />
              ) : tendersStore ? (
                tendersStore.map((item) => (
                  <Grid xl={4} lg={4} md={4} sm={4} xs={12}>
                    <TenderCard
                      key={item.id}
                      id={item.id}
                      header={item.tender_type && item.tender_type.map((item) => item.name)}
                      contract={item.description}
                      orderNumber={item.competition_number}
                      field={item.type}
                      location={item.submit_place}
                      image={item?.tender_type && item?.tender_type?.filter((item) => item?.image)[0]?.image}
                      onClick={handleAddTenders}
                    />
                  </Grid>
                ))
              ) : (
                <Typography sx={{ fontSize: "2rem", color: "red" }}>
                  {global.tenderNotFound[lang]}
                </Typography>
              )}
            </Grid>
          </Stack>
          <Button
            sx={{ width: "20rem", fontSize: "2rem" }}
            onClick={handelAllTenders}
          >
            {global.showAll[lang]}
          </Button>
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default Tenders;
