//************************  React import   ************************ //
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Wrapper } from "../../components";
import FormTable from "../../components/FormTable";
import MultiSelect from "../../components/MultiSelect";
import pageHoc from "../../features/accessibility/hoc/PageHoc";

import ObjNotFalse from "../../ObjNotFalse";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Stack,
} from "@mui/material";

// Show Password Icons
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Select Icon
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//************************ Constant Data import   ************************ //
import { REGISTER, TENDERACTIVITY, USERINFO, USERS } from "../../data/apis";
import { ALLACCESS } from "../../data/semantics";

//************************  Assets import   ************************ //
import CheckIcon from "@mui/icons-material/Check";
import backgroundImage from "../../assets/Betngan.png";

//************************  Library import   ************************ //
// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

//////////////////////////////////////////////////////////////////////////

const UserInformations = () => {
  //************************  Hooks   ************************ //
  // get user id from url
  const { id } = useParams();

  // Set and Get data from userInfoSlice
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const global = useSelector((state) => state.lang.value.global);
  /////////////////////
  const userInfoStore = useSelector((state) => state.userInfo.value);

  // Activity Names
  const tenderActivityStore = useSelector(
    (state) => state.tenderactivity.value
  );

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Get Tender Activity data
  const [activityReq, activitydata] = useFetch({
    url: TENDERACTIVITY,
    method: "get",
  });

  //*************************************************************** //

  //************************  useEffect ************************ //

  //*************************************************************** //

  //************************  Js Functions Code ************************ //

  // Get Tender Activity data from server and set it into tenderactivity redux store
  const handleOpen = () => {
    activityReq({
      onSuccess: (res) => {
        dispatch({ type: "tenderactivity/set", payload: res.data });
      },
    });
  };

  //*************************************************************** //

  //************************  Form Js Code ************************ //

  // From validation
  const registerFormSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.number("must be a number").required("Required"),
    interests: Yup.array("").required("Interests Required"),
  });
  const [request, data] = useFetch({ url: USERS, method: "patch" });

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: userInfoStore.name,
      email: userInfoStore.email,
      // phone: userInfoStore.phone.replace(userInfoStore.country_code, ""),
      phone: userInfoStore.phone,
      interests: userInfoStore.interests.map((item) => item.id),
    },
    enableReinitialize: true,

    validationSchema: registerFormSchema,
    onSubmit: () => {
      const data = {
        name: values.name !== userInfoStore.name && values.name,
        email: values.email !== userInfoStore.email && values.email,
        phone: values.phone !== userInfoStore.phone && `${values.phone}`,
        interests:
          JSON.stringify(values.interests) !==
            JSON.stringify(userInfoStore.interests.map((item) => item.id)) &&
          values.interests.map((item) => ({ id: item })),
      };

      // send data to server
      request({
        onSuccess: (res) => {
          dispatch({ type: "userInfo/set", payload: res.data });
          handleReset();
        },
        body: ObjNotFalse(data),
        aditions: [`${id}`],
        onFail: (err) => {
          console.log(err);
        },
      });
    },
  });
  //*************************************************************** //

  ////////////////////////////

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        paddingBlock: {
          xs: "8rem",
          sm: "8rem",
          md: "8rem",
          lg: "8rem",
          xl: "2.5rem",
        },
      }}
    >
      <Wrapper>
        <FormTable
          onSubmit={handleSubmit}
          fromHeader={"تعديل الحساب الشخصي"}
          submitButtonLabel={global.save[lang]}
          isPending={data.isPending}
        >
          <TextField
            id="name"
            label="الاسم الحقيقي"
            variant="standard"
            name="name"
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            sx={{
              "& input": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              "	.MuiInputLabel-root": {
                color: "#fff",
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: "#fff",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.2rem",
                margin: 0,
              },
            }}
          />
          <TextField
            id="email"
            label="البريد الالكتروني"
            variant="standard"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            sx={{
              "& input": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              "	.MuiInputLabel-root": {
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1.5rem",
                  xl: "1.5rem",
                },
                color: "#fff",
              },
              ".MuiInputLabel-root.Mui-focused": {
                color: "#fff",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "1.2rem",
                margin: 0,
              },
            }}
          />
          <PhoneInput
            // country={"eg"}
            enableSearch
            value={values.phone}
            onChange={(phone) => setFieldValue("phone", `+${phone}`)}
            containerStyle={{ direction: "ltr" }}
            inputStyle={{
              width: "100%",
              color: "#fff",
              backgroundColor: "transparent",
              border: "unset",
              borderBottom: "1px solid #EF5435",
            }}
            buttonStyle={{
              backgroundColor: "transparent",
              border: "unset",
            }}
            dropdownClass="country" //css class name
            dropdownStyle={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              backdropFilter: "blur(15px)",
              borderRadius: "0 0 0 15px",
            }}
          />

          <Stack spacing={2}>
            <Box sx={{ paddingTop: 2, minWidth: "100%" }}>
              <MultiSelect
                placeholder={global.chooseBids[lang]}
                label={true}
                sx={{
                  width: "100%",
                  backdropFilter: "0",

                  "&  .MuiOutlinedInput-input": {
                    fontSize: 15,

                    borderBottom: "1px solid #EF5435",
                    padding: {
                      xs: 2,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 2,
                    },
                    color: "#fff",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#fff",
                    fontSize: {
                      xs: "1.2rem",
                      sm: "1.2rem",
                      md: "1.5rem",
                      lg: "1.5rem",
                      xl: "1.5rem",
                    },
                  },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    fontSize: 10,
                  },
                }}
                value={values.interests}
                isPending={activitydata.isPending}
                onChange={(e) => setFieldValue("interests", e.target.value)}
                onOpen={handleOpen}
                renderValue={(selected) => {
                  return selected
                    .map(
                      (consult) =>
                        tenderActivityStore?.results?.find((item) => consult === item.id)
                          ?.name
                    )
                    .filter((item) => Boolean(item)).length
                    ? selected
                        .map(
                          (consult) =>
                            tenderActivityStore?.results?.find(
                              (item) => consult === item.id
                            )?.name
                        )
                        .join(" - ")
                    : userInfoStore.interests
                        .map((item) => item.name)
                        .join(" - ");
                }}
              >
                {tenderActivityStore.results?.map((item, index) => (
                  <MenuItem value={item.id} key={`item ${index}`}>
                    {item.name}
                    {values.interests.find(
                      (interest) => interest === item.id
                    ) && (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ width: "100%" }}
                      >
                        <CheckIcon sx={{ color: "green" }} />
                      </Stack>
                    )}
                  </MenuItem>
                ))}
              </MultiSelect>
            </Box>
          </Stack>
        </FormTable>
        {data.successAlert}
        {data.failAlert}
      </Wrapper>
    </Box>
  );
};

export default pageHoc(UserInformations, ALLACCESS, "/login");
