// Intl.DateTimeFormat().resolvedOptions().timeZone

import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { cacheRtl, cacheLtr } from "./theme";
import { useDispatch, useSelector } from "react-redux";
import { Topnav } from "./components";
import Topbar from "./layout/Topbar";
import pages from "./data/pages";
import AddSingleTenders from "./pages/AddSingleTenders/AddSingleTenders";
import Topnav2 from "./layout/Topnav2";
import * as ReactIs from "react-is";
import useFetch from "./hooks/useFetch";
import { USERINFO } from "./data/apis";

function App() {
  const lang = useSelector((state) => state.lang.value.lang);
  const token = useSelector((state) => state.userInfo.value.token);
  const dispatch = useDispatch();

  const [request, data] = useFetch({ url: USERINFO, method: "get" });

  useEffect(() => {
    document.dir = lang === "en" ? "ltr" : "rtl";
  }, [lang]);

  useEffect(() => {
    if (token) {
      request({
        onSuccess: (res) => {
          dispatch({ type: "userInfo/set", payload: res.data });
        },
      });
    }

   
  }, []);

  return (
    <CacheProvider value={lang === "en" ? cacheLtr : cacheRtl}>
      <>
        <Topbar />
        <Topnav2 />
      </>
      <Routes>
        {pages.map((page, index) => {
          if (!Boolean(page.component)) return;

          return (
            <Route
              path={page.path}
              element={page.component}
              key={`route ${index}`}
            />
          );
        })}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </CacheProvider>
  );
}

export default App;
