//************************  React import   ************************ //
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//************************ Redux import   ************************ //
import { useDispatch, useSelector } from "react-redux";

//************************ Custom Component import   ************************ //

import { Wrapper } from "../../../components";
import AccessGate from "../../../features/accessibility/components/AccessGate";

//************************  Ads images import   ************************ //

import ad_one from "../../../assets/Consult_one.jpeg";
import ad_two from "../../../assets/Consult_two.jpeg";

import ad_three from "../../../assets/Consult_three.jpeg";

import ad_four from "../../../assets/Consult_four.jpeg";
import ad_five from "../../../assets/Consult_five.jpeg";

import ad_six from "../../../assets/Consult_six.jpeg";

import homeImg from '../../../assets/home-2.jpg'

//************************ Data import   ************************ //
import { OFFICE_USER, USER } from "../../../data/semantics";
import { CONSULTDEP, TENDERACTIVITY } from "../../../data/apis";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

//************************  Assets import   ************************ //
import heroSecBackground from "../../../assets/GetProfitBaner.jpg";

//************************  Library import   ************************ //
// react-multi-carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

/////////////////////////////////////////////////////////////////////////////

const Hero = () => {
  //************************  Hooks   ************************ //

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //*************************************************************** //

  //************************  Redux Selector   ************************ //

  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const heroLang = useSelector((state) => state.lang.value.home.hero);
  const global = useSelector((state) => state.lang.value.global);
  const ads = [ad_one, ad_two, ad_three, ad_four, ad_five, ad_six]
  // get user role
  const { role } = useSelector((state) => state.userInfo.value);
  const userInfo = useSelector((state) => state.userInfo.value);

  // console.log(userInfo);

  // Activity Names
  const consultanciesDepStore = useSelector(
    (state) => state.consultanciesDep.value
  );

  //*************************************************************** //

  //************************  API Request   ************************ //

  // Get consult department
  const [request, data] = useFetch({
    url: CONSULTDEP,
    method: "get",
  });

  const getConsultance = () => {
    request({
      onSuccess: (res) => {
        dispatch({ type: "consultanciesDep/set", payload: res.data });
      },
    });
  };

  //*************************************************************** //

  //************************  useEffect ************************ //

  useEffect(() => {
    getConsultance();
  }, []);

  //*************************************************************** //

  //************************  Js Functions Code ************************ //
  // go to consulties Page
  const toConsult = (e, consultancy) => {
    console.log(consultancy)
    navigate("/AddSingleConsultancy", { state: consultancy });
  };

  // return to top
  const toUpPage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Carousel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  //*************************************************************** //

  // const isMobile = useMediaQuery("(max-width: 786px)");
  // const isDesktop = useMediaQuery("(min-width: 1224px)");
  //////////////////
console.log(consultanciesDepStore);
  return (
    <>
      <Box
        sx={{
          width: "5rem",
          height: "5rem",
          borderRadius: "50%",
          cursor: "pointer",
          bgcolor: "#EF5435",
          position: "fixed",
          bottom: 10,
          right: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover .MuiSvgIcon-root": {
            transform: "translateY(-4px)",
            transition: "all 0.3s",
          },
        }}
        onClick={toUpPage}
      >
        <ArrowUpwardIcon
          sx={{
            fontSize: "2.5rem",
          }}
        />
      </Box>
      <Box
        id="home"
        sx={{
          backgroundImage: `url(${heroSecBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "900px",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",

          width: "100%",
        }}
      >
        <Wrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: {
                  xs: "2.75rem",
                  sm: "2.75rem",
                  md: "3.75rem",
                  lg: "3.75rem",
                  xl: "3.75rem",
                },
              }}
            >
              {heroLang.header[lang]}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontSize: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "3rem",
                  xl: "3rem",
                },
              }}
            >
              {heroLang.subHeader[lang]}
            </Typography>

            {/* <AccessGate roles={["user"]}>
              <Stack direction={"row"} spacing={5}>
                <Button
                  onClick={() => {
                    navigate("/AddSingleTenders");
                  }}
                  sx={{
                    fontSize: "2.5rem",
                    padding: "1rem",
                    color: "#fff",
                    bgcolor: "transparent",
                    backgroundImage: "unset",
                    border: "1px solid #fff",

                    "&:hover": {
                      backgroundImage: "unset",
                    },
                  }}
                >
                  أضف مناقصه
                </Button>

                <Button
                  onClick={() => {
                    navigate("/consultancies");
                  }}
                  sx={{ fontSize: "2.5rem", padding: "1rem", color: "#000" }}
                >
                  أطلب أستشاره
                </Button>
              </Stack>
            </AccessGate> */}

            {/* <Searchbar
              sx={{ marginBlock: "20px" }}
              placeholder={global.search[lang]}
            /> */}

            {data.isPending ? (
              <CircularProgress />
            ) : (
              <Box
                sx={{
                  maxWidth: {
                    xs: 900,
                    sm: 900,
                    md: 800,
                    lg: 800,
                    xl: 800,
                  },
                  width: "100%",
                  WebkitMaskImage: {
                    xs: "linear-gradient(to left, rgba(9,9,121,0), rgba(255,255,255,1) 10% 90%, rgba(9,9,121,0))",
                    sm: "linear-gradient(to left, rgba(9,9,121,0), rgba(255,255,255,1) 10% 90%, rgba(9,9,121,0))",
                    md: "unset",
                    lg: "unset",
                    xl: "unset",
                  },
                  overflowX: "auto",
                }}
              >
                <Box
                  sx={{
                    "& .react-multi-carousel-item": {
                      marginInline: {
                        xs: 0,
                        sm: 0.3,
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                    },
                  }}
                >
                  <Carousel
                    responsive={responsive}
                    arrows={false}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2500}
                  >
                    {consultanciesDepStore?.results?.length > 0 &&
                    consultanciesDepStore?.results?.map((consultancy,index) => (
                     
                      <Card
                    
                        img={consultancy?.image ? consultancy.image : ad_four}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          role !== OFFICE_USER && toConsult(e, consultancy)
                        }
                        key={`consult ${consultancy.id}${index}`}
                      
                      >
                      </Card>
                    ))}
                    {/* {consultanciesDepStore?.map((consultancy) => (
                      <Card
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          role !== OFFICE_USER && toConsult(e, consultancy)
                        }
                        key={`tenderActivity ${consultancy.id}`}
                      >
                        {consultancy.name}
                      </Card>
                    ))} */}

                    {/* {ads.map((ad) => (
                      <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          borderRadius: "0 60px 0 60px",
                          // border: "1px solid #fff",
                          
                          height: "100%",

                          paddingInline: 1,
                          overflow: "hidden",
                        }}
                     
                        onClick={(e) =>
                          role !== OFFICE_USER && toConsult(e)
                        }
                        key={`ad ${ad}`}
                      >
                        <Box sx={{ height:'100%' }}>
                          <img
                            src={ad}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Box>
                      </Stack>
                    ))} */}
                  </Carousel>
                </Box>
              </Box>
            )}

            <Typography variant="h2" sx={{ fontSize: "2.5rem", color: "#fff" }}>
              اشتراك اعضاء مجموعه توب فيجن{" "}
              <Typography
                variant="h2"
                sx={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  color: "#EF5435",
                  display: "inline-block",
                }}
              >
                مجانا
              </Typography>
            </Typography>

            <Box>
              <a href="https://maroof.sa/277943" target="_blank">
              
                  <img src={homeImg}/>
              </a>
            </Box>
          </Box>
        </Wrapper>
      </Box>
    </>
  );
};

export default Hero;

const Card = ({ children, img, onClick, sx = {} }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        padding: {
          xs: "10px 5px",
          sm: "10px 5px",
          md: "30px 20px",
          lg: "30px 20px",
          xl: "30px 20px",
        },
        // backgroundImage: `url(${img})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // border: "3px solid white",
        // backdropFilter: "blur(20px)",
        // borderRadius: "0 20px 0 20px",
        maxWidth: {
          xs: 160,
          sm: 160,
          md: 160,
          lg: "100%",
          xl: "100%",
        },
        minHeight: 150,
        height: "max-content",
        ...sx,
      }}
    >
      <img src={img} style={{ width: "100%", height: "100%" }}/>
      {/* <Typography
        variant="h4"
        sx={{ color: "white", width: "100%", wordBreak: "break-word" }}
      >
        {children}
      </Typography> */}
    </Box>
  );
};

const Searchbar = ({ value, placeholder, onChange = () => { }, sx = {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 800,
        minWidth: 100,
        height: 50,
        backgroundImage:
          "linear-gradient(10deg, #ffffff3d, #ffffff30, #ffffff3d)",
        border: "3px solid white",
        backdropFilter: "blur(3px)",
        borderRadius: "0 20px 0 20px",
        ...sx,
      }}
    >
      <TextField
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-input:focus::placeholder": {
            opacity: "1",
          },
          "& .MuiOutlinedInput-input": {
            color: "white",
            fontSize: 20,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: "white" }} fontSize="large" />
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
    </Box>
  );
};
