//************************  React import   ************************ //
import React from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************  Matrial UI import   ************************ //
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";

const DataTable = (props) => {
  //************************  Props data   ************************ //
  const details = props.data;

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const dataTableLang = useSelector((state) => state.lang.value.dataTable);
  const global = useSelector((state) => state.lang.value.global);

  //*************************************************************** //

  /////////////////////////////////////////////////////////////////////////////

  return (
    <Box
      sx={{
        border: "2px solid #EF5435",
        borderRadius: " 0 15px 0 15px",
        overflow: "hidden",
      }}
    >
      <Box
        component={"table"}
        sx={{
          width: "100%",
          margin: "auto",
          fontSize: "2rem",
          borderRadius: "10px 0 10px 0",
          borderCollapse: "collapse",
          borderStyle: "hidden",

          "& td": {
            border: "2px solid #EF5435",
            padding: 2,
          },

          "& td:first-child": {
            width: "20%",
            textAlign: "center",
            color: "#EF5435",
          },
        }}
      >
        <Box component={"tbody"}>
          <tr>
            <td>{dataTableLang.activity[lang]}</td>
            <td>
              {Boolean(details.id) ? details.tender_type?.name : <Skeleton />}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.announcementDate[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.created_date?.split("T")[0].replace(/-/gi, "/")
              ) : (
                <Skeleton />
              )}
            </td>
          </tr>

          <tr>
            <td> {dataTableLang.competitionNo[lang]}</td>
            <td>
              {Boolean(details.id) ? details.competition_number : <Skeleton />}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.tenderSubject[lang]}</td>
            <td>{Boolean(details.id) ? details.description : <Skeleton />}</td>
          </tr>

          <tr>
            <td>{dataTableLang.competitionType[lang]}</td>
            <td>{Boolean(details.id) ? details.type : <Skeleton />}</td>
          </tr>

          <tr>
            <td>{dataTableLang.advertiserName[lang]}</td>
            <td>
              {Boolean(details.id) ? details.Organization_name : <Skeleton />}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.city[lang]}</td>
            <td>{Boolean(details.id) ? details.city : <Skeleton />}</td>
          </tr>

          <tr>
            <td>{dataTableLang.placeOfSubmission[lang]}</td>
            <td>{Boolean(details.id) ? details.submit_place : <Skeleton />}</td>
          </tr>

          <tr>
            <td>{dataTableLang.bidRequirements[lang]}</td>
            <td>
              {Boolean(details.id) ? details.requirements_val : <Skeleton />}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.termsSheet[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.requriemnts_sale_place
              ) : (
                <Skeleton />
              )}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.phone[lang]}</td>
            <td>{Boolean(details.id) ? details.phone : <Skeleton />}</td>
          </tr>

          <tr>
            <td>{dataTableLang.deadline[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.Deadline?.split("T")[0].replace(/-/gi, "/")
              ) : (
                <Skeleton />
              )}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.openingTheEnvelopes[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.envelope_opening_date?.split("T")[0].replace(/-/gi, "/")
              ) : (
                <Skeleton />
              )}
            </td>
          </tr>

          {/* <tr>
            <td>{dataTableLang.extensionDeadline[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.Deadline_extend?.split("T")[0].replace(/-/gi, "/")
              ) : (
                <Skeleton />
              )}
            </td>
          </tr> */}

          <tr>
            <td>{dataTableLang.extensionOpeningTheEnvelopes[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.envelope_opening_extend
                  ?.split("T")[0]
                  .replace(/-/gi, "/")
              ) : (
                <Skeleton />
              )}
            </td>
          </tr>

          <tr>
            <td>{dataTableLang.source[lang]}</td>
            <td>{Boolean(details.id) ? details.reference : <Skeleton />}</td>
          </tr>

          <tr>
            <td>{dataTableLang.tenderCode[lang]}</td>
            <td>
              {Boolean(details.id) ? (
                details.code ? (
                  details.code
                ) : (
                  global.notFound[lang]
                )
              ) : (
                <Skeleton />
              )}
            </td>
          </tr>
        </Box>
      </Box>
    </Box>
  );
};

export default DataTable;
