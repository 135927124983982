//************************  React import   ************************ //
import React, { useEffect } from "react";
import { useState } from "react";

//************************ Redux import   ************************ //
import { useSelector } from "react-redux";

//************************ Custom Component import   ************************ //
import { Button, Wrapper, PartnersCard } from "../../../components";
import Heading from "../../../components/Heading";

//************************ Custom Hooks import   ************************ //
import useFetch from "../../../hooks/useFetch";

//************************  Matrial UI import   ************************ //
import { Box, CircularProgress, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

//************************ Constant Data import   ************************ //
import { PARTNERS } from "../../../data/apis";

//************************  Library import   ************************ //
// react-multi-carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/////////////////////////////////////////////////////////////////////////////

const Partners = () => {
  //************************  Hooks   ************************ //
  const [partnersImgs, setPartnersImgs] = useState([]);

  //*************************************************************** //

  //************************  Redux Selector   ************************ //
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const partnersLang = useSelector((state) => state.lang.value.home.partners);

  //*************************************************************** //

  //************************  API Request   ************************ //
  // Get partners Images
  const [partnersRequest, partnersData] = useFetch({
    url: PARTNERS,
    method: "get",
  });

  //*************************************************************** //

  //************************  useEffect ************************ //
  useEffect(() => {
    partnersRequest({
      onSuccess: (res) => {
        setPartnersImgs(res?.data);
      },
    });
  }, []);

  //*************************************************************** //
console.log();
  // Carousel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Box className="global-padding-y" id="partners">
      <Wrapper>
        <Stack spacing={4} alignItems={"center"}>
          <Heading>{partnersLang[lang]}</Heading>

          {partnersData.isPending ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                "& .react-multi-carousel-item": { marginInline: 0.2 },
                width: "100%",
              }}
            >
              <Carousel
                responsive={responsive}
                arrows={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
              >
                {partnersImgs?.results?.length > 0 &&
                partnersImgs?.results?.map((item, index) => (
                  <PartnersCard key={`partner ${index}`} image={item?.image} url={item?.url} />
                ))}
              </Carousel>
            </Box>
          )}
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default Partners;
