import React, { useState } from "react";
import { Button, Heading, Wrapper } from "../../components";
import {
  Box,
  Stack,
  Select,
  MenuItem,
  Input,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grow,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Autocomplete } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import Inputs from "../../components/Inputs";
import backgroundImage from "../../assets/Betngan.png";

// Form Validation
import { useFormik } from "formik";
import * as Yup from "yup";

// Select Icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateInput from "../../components/DateInput";
import SelectInput from "../../components/SelectInput";
import useFetch from "../../hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { LASTTENDER, TENDERACTIVITY, TENDERS } from "../../data/apis";
import MultiSelect from "../../components/MultiSelect";
import { useNavigate } from "react-router-dom";
import { ALLACCESS } from "../../data/semantics";
import pageHoc from "../../features/accessibility/hoc/PageHoc";
import { useEffect } from "react";
import { useRef } from "react";
import { PersonPinCircleOutlined } from "@mui/icons-material";

const AddSingleTenders = () => {
  // Get lang from Store
  const lang = useSelector((state) => state.lang.value.lang);
  const addTendersLang = useSelector((state) => state.lang.value.addTenders);
  const global = useSelector((state) => state.lang.value.global);
  /////////////////////

  ////////////////////// Files selection ///////////////////

  // File One
  const [selectedFile1, setSelectedFile1] = useState("");
  const [previewFile1, setPreviewFile1] = useState("");
  const inputFile1 = useRef();
  ////////////////////////

  // File Two
  const [selectedFile2, setSelectedFile2] = useState("");
  const [previewFile2, setPreviewFile2] = useState("");
  const inputFile2 = useRef();
  ////////////////////////

  // File Three
  const [selectedFile3, setSelectedFile3] = useState("");
  const [previewFile3, setPreviewFile3] = useState("");
  const inputFile3 = useRef();
  ////////////////////////

  // Navigate
  const navigate = useNavigate();

  const handleGoOut = () => {
    window.scrollTo({
      top: 0,
    });
    navigate(`/tenders`);
  };
  //////////////////////

  // Get last
  const [lastTenderRequest, lastTenderData] = useFetch({
    url: LASTTENDER,
    method: "get",
  });

  const date = new Date().toISOString();

  const [lastTender, setLastTender] = useState({
    tender_type: "",
    requriemnts_sale_place: "",
    created_date: "",
    description: "",
    phone: "",
    Deadline: "",
    type: "",
    envelope_opening_date: "",
    Organization_name: "",
    Deadline_extend: "",
    city: "",
    envelope_opening_extend: "",
    submit_place: "",
    reference: "",
    requirements_val: "",
    code: "",
    note: "",
  });

  useEffect(() => {
    lastTenderRequest({
      onSuccess: (res) => {
        let data = res.data;

        Object.keys(data).length !== 0 && setLastTender({ ...data });
        handleOpen();
      },
    });
    handleTypeOpen();
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  ////////////////////// Image selection ///////////////////
  // const [selectedImage, setSelectedImage] = useState("");
  // const [preview, setPreview] = useState("");

  // useEffect(() => {
  //   inputImage.current.value = "";
  // }, [selectedImage]);

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Grow ref={ref} {...props} unmountOnExit />;
  // });

  ///////////////////////

  const addSingleTendersSchema = Yup.object({
    tender_type: Yup.string().required("Office required"),
    requriemnts_sale_place: Yup.string().required("consultancy required"),
    created_date: Yup.string().required("consultancy Date required"),
    description: Yup.string().required("consultancy Topic required"),
    phone: Yup.string("Phone required")
      .matches(/^[0-9]+$/, "")
      .required("Price required"),
    Deadline: Yup.string().required("consultancy Topic required"),
    type: Yup.string().required("consultancy Type required"),
    envelope_opening_date: Yup.string().required(
      "Envelope Opning Date  required"
    ),
    Organization_name: Yup.string().required("advertiser Name required"),
    Deadline_extend: Yup.string().required(
      "Postpone Consultancy Bid Date required"
    ),
    city: Yup.string().required("City Name required"),
    envelope_opening_extend: Yup.string().required(
      "Postpone Consultancy Opning Date Name required"
    ),

    submit_place: Yup.string().required("Submission Location required"),
    reference: Yup.number().required("Location required"),
    requirements_val: Yup.number().required("Price required"),
    code: Yup.string().required("Code required"),
  });

  const [tenderRequest, tenderData] = useFetch({
    url: TENDERS,
    method: "post",
  });

  // Get data from server
  const [tenderTypeRequest, tenderTypeData] = useFetch({
    url: TENDERACTIVITY,
    method: "get",
  });
  const [tendersType, setTendersType] = useState([]);
  const defaultProps = {};

  const handleOpen = () => {
    tenderTypeRequest({
      onSuccess: (res) => {
        setTendersType(res.data);
      },
    });
  };

  ///////////////////////

  const [typeData, setTypeData] = useState([]);

  const handleTypeOpen = () => {
    setTypeData([
      "مناقصة",
      "مزايدة",
      "بيع بالمزاد",
      "استئجار مباني",
      "منافسة",
      "أخرى",
    ]);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: lastTender,
    enableReinitialize: true,

    onSubmit: () => {
      const formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        if (value !== "" || value !== null) {
          formData.append(key, value);
        }
      });

      // formData && formData.append("image", selectedImage);
      formData && formData.append("file_one", selectedFile1);
      formData && formData.append("file_two", selectedFile2);
      formData && formData.append("file_three", selectedFile3);

      tenderRequest({
        onSuccess: (res) => {
          handleReset();
          // Delete Selected Files
          //File One
          setPreviewFile1("");
          setSelectedFile1("");
          inputFile1.current.value = "";

          //File Two
          setPreviewFile2("");
          setSelectedFile2("");
          inputFile2.current.value = "";
          //File Three
          setPreviewFile3("");
          setSelectedFile3("");
          inputFile3.current.value = "";
          /////////////////////

          setTimeout(() => {
            handleGoOut();
          }, 1500);
        },
        body: formData,
        onFail: (err) => {
          console.log(err);
        },
      });
    },
  });
  console.log(values?.tender_type);
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        paddingBlock: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 25,
          xl: 20,
        },
        height: {
          xs: "auto",
          sm: "auto",
          md: "auto",
          lg: "110vh",
          xl: "110vh",
        },

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Wrapper>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
          sx={{
            marginTop: {
              xs: "10rem",
              sm: "10rem",
              md: "10rem",
              lg: "10rem",
              xl: "10rem",
            },
            marginBottom: {
              xs: "3rem",
              sm: "3rem",
              md: "5rem",
              lg: "0",
              xl: "0",
            },
          }}
        >
          <Heading sx={{ fontSize: "3rem" }}>{global.addTender[lang]}</Heading>

          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              gap: "2rem",
            }}
          >
            <Grid spacing={2} sx={{ width: "100%" }}>
              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <AutocompleteField
                      sx={{
                        "& 	.MuiAutocomplete-inputRoot": {
                          fontSize: {
                            xs: "1.2rem",
                            sm: "1.2rem",
                            md: "1.5rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                          borderRadius: "0 15px 0 15px",
                          border: "1px solid #fff",
                          padding: {
                            xs: 2,
                            sm: 2,
                            md: 1.2,
                            lg: 1.2,
                            xl: 1.2,
                          },
                          color: "#fff",
                          padding: "5px",
                        },
                        "& .muirtl-sog54u-MuiInputBase-root-MuiInput-root::before":
                          {
                            borderBottom: " 0 !important",
                          },
                        "& .muirtl-sog54u-MuiInputBase-root-MuiInput-root::after":
                          {
                            borderBottom: "0 !important",
                          },
                        "& .muirtl-qb7qih::before": {
                          borderBottom: "0 !important",
                        },
                        "& .muirtl-qb7qih::after": {
                          borderBottom: "0 !important",
                        },
                        "& 	.Mui-focused": {
                          border: "1px solid #F3722E",
                        },
                     
                        "& .MuiAutocomplete-popupIndicator": {
                          color: "white !important",
                        },
                      }}
                      ListboxProps={{
                        sx: {
                          fontSize: "1.6rem",
                          backdropFilter: "blur(35px)",
                          color: "#fff",
                          "& li:hover": {
                            background: "#6B2B1D !important",
                          },

                          // height: 300,
                          // // bgcolor: "rgba(128 ,47 ,31,0.5)",

                          background: "rgba(0,0,0,0.9)",
                          // filter: "blur(5px) ",
                          // borderRadius: "0 0 0 15px",
                        },
                      }}
                      data={tendersType?.results?.map((type) => ({
                        label: type.name,
                        value: type.id,
                      }))}
                      placeholder={addTendersLang.tender_type_id[lang]}
                      onOpen={handleOpen}
                      value={values.tender_type}
                      isPending={tenderTypeData.isPending}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(e, options, reason) => {
                        switch (reason) {
                          case "selectOption":
                            setFieldValue("tender_type", options.value);
                            break;
                          case "clear":
                            setControl("tender_type", "");
                        }
                      }}
                    />
                    {/* <MultiSelect
                      placeholder={addTendersLang.tender_type_id[lang]}
                      sx={{
                        width: "100%",
                        "&  .MuiOutlinedInput-input": {
                          fontSize: {
                            xs: "1.2rem",
                            sm: "1.2rem",
                            md: "1.5rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                          borderRadius: "0 15px 0 15px",
                          border: "1px solid #fff",
                          padding: {
                            xs: 2,
                            sm: 2,
                            md: 1.2,
                            lg: 1.2,
                            xl: 1.2,
                          },
                          color: "#fff",
                        },

                        "& .MuiSelect-select": {
                          padding: 0,
                        },
                      }}
                      value={values.tender_type}
                      isPending={tenderTypeData.isPending}
                      multiple={false}
                      onChange={(e) =>
                        setFieldValue("tender_type", e.target.value)
                      }
                      onOpen={handleOpen}
                      renderValue={(selected) => {
                        return tendersType.find((item) => item.id === selected)
                          ?.name;
                      }}
                    >
                      {tendersType.map((item, index) => (
                        <MenuItem value={item.id} key={`item ${index}`}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </MultiSelect> */}
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"requriemnts_sale_place"}
                      variant={"standard"}
                      name={"requriemnts_sale_place"}
                      placeholder={addTendersLang.requriemnts_sale_place[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.requriemnts_sale_place}
                      onBlur={handleBlur}
                      error={
                        touched.requriemnts_sale_place &&
                        Boolean(errors.requriemnts_sale_place)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box>
                      <DateInput
                        id={"created_date"}
                        variant={"standard"}
                        name={"created_date"}
                        placeholder={addTendersLang.created_date[lang]}
                        type={"input"}
                        width={"100%"}
                        height={"3rem"}
                        // setValue={setAnnouncementDate}
                        value={
                          values.created_date &&
                          values.created_date.split("T")[0].replace(/-/gi, "/")
                        }
                        onValueChange={(itemValue) => {
                          setFieldValue("created_date", itemValue);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.created_date && Boolean(errors.created_date)
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"phone"}
                      variant={"standard"}
                      name={"phone"}
                      placeholder={addTendersLang.phone[lang]}
                      type={"text"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.phone
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1")}
                      onBlur={handleBlur}
                      error={touched.phone && Boolean(errors.phone)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"description"}
                      variant={"standard"}
                      name={"description"}
                      placeholder={addTendersLang.description[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.description}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box>
                      <DateInput
                        id={"Deadline"}
                        variant={"standard"}
                        name={"Deadline"}
                        placeholder={addTendersLang.Deadline[lang]}
                        type={"input"}
                        width={"100%"}
                        height={"3rem"}
                        // setValue={setAnnouncementDate}
                        value={
                          values.Deadline &&
                          values.Deadline.split("T")[0].replace(/-/gi, "/")
                        }
                        onValueChange={(itemValue) => {
                          setFieldValue("Deadline", itemValue);
                        }}
                        onBlur={handleBlur}
                        error={touched.Deadline && Boolean(errors.Deadline)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <MultiSelect
                      placeholder={addTendersLang.type[lang]}
                      sx={{
                        width: "100%",
                        "&  .MuiOutlinedInput-input": {
                          fontSize: {
                            xs: "1.2rem",
                            sm: "1.2rem",
                            md: "1.5rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                          borderRadius: "0 15px 0 15px",
                          border: "1px solid #fff",
                          padding: {
                            xs: 2,
                            sm: 2,
                            md: 1.2,
                            lg: 1.2,
                            xl: 1.2,
                          },
                          color: "#fff",
                        },

                        "& .MuiSelect-select": {
                          padding: 0,
                        },
                      }}
                      value={values.type}
                      multiple={false}
                      onChange={(e) => setFieldValue("type", e.target.value)}
                      onOpen={handleTypeOpen}
                      renderValue={(selected) => {
                        return typeData.find((item) => item === selected);
                      }}
                    >
                      {typeData.map((item, index) => (
                        <MenuItem value={item} key={`item ${index}`}>
                          {item}
                        </MenuItem>
                      ))}
                    </MultiSelect>
                  </Grid>

                  {/* <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"type"}
                      variant={"standard"}
                      name={"type"}
                      placeholder={addTendersLang.type[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.type}
                      onBlur={handleBlur}
                      error={touched.type && Boolean(errors.type)}
                    />
                  </Grid> */}

                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box>
                      <DateInput
                        id={"envelope_opening_date"}
                        variant={"standard"}
                        name={"envelope_opening_date"}
                        placeholder={addTendersLang.envelope_opening_date[lang]}
                        type={"input"}
                        width={"100%"}
                        height={"3rem"}
                        value={
                          values.envelope_opening_date &&
                          values.envelope_opening_date
                            .split("T")[0]
                            .replace(/-/gi, "/")
                        }
                        onValueChange={(itemValue) => {
                          setFieldValue("envelope_opening_date", itemValue);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.envelope_opening_date &&
                          Boolean(errors.envelope_opening_date)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"Organization_name"}
                      variant={"standard"}
                      name={"Organization_name"}
                      placeholder={addTendersLang.Organization_name[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.Organization_name}
                      onBlur={handleBlur}
                      error={
                        touched.Organization_name &&
                        Boolean(errors.Organization_name)
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box>
                      <DateInput
                        id={"Deadline_extend"}
                        variant={"standard"}
                        name={"Deadline_extend"}
                        placeholder={addTendersLang.Deadline_extend[lang]}
                        type={"input"}
                        width={"100%"}
                        height={"3rem"}
                        value={
                          values.Deadline_extend &&
                          values.Deadline_extend.split("T")[0].replace(
                            /-/gi,
                            "/"
                          )
                        }
                        onValueChange={(itemValue) => {
                          setFieldValue("Deadline_extend", itemValue);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.Deadline_extend &&
                          Boolean(errors.Deadline_extend)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"city"}
                      variant={"standard"}
                      name={"city"}
                      placeholder={addTendersLang.city[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.city}
                      onBlur={handleBlur}
                      error={touched.city && Boolean(errors.city)}
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box>
                      <DateInput
                        id={"envelope_opening_extend"}
                        variant={"standard"}
                        name={"envelope_opening_extend"}
                        placeholder={
                          addTendersLang.envelope_opening_extend[lang]
                        }
                        type={"input"}
                        width={"100%"}
                        height={"3rem"}
                        value={
                          values.envelope_opening_extend &&
                          values.envelope_opening_extend
                            .split("T")[0]
                            .replace(/-/gi, "/")
                        }
                        onValueChange={(itemValue) => {
                          setFieldValue("envelope_opening_extend", itemValue);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.envelope_opening_extend &&
                          Boolean(errors.envelope_opening_extend)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"submit_place"}
                      variant={"standard"}
                      name={"submit_place"}
                      placeholder={addTendersLang.submit_place[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.submit_place}
                      onBlur={handleBlur}
                      error={
                        touched.submit_place && Boolean(errors.submit_place)
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"reference"}
                      variant={"standard"}
                      name={"reference"}
                      placeholder={addTendersLang.reference[lang]}
                      type={"input"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.reference}
                      onBlur={handleBlur}
                      error={touched.reference && Boolean(errors.reference)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"requirements_val"}
                      variant={"standard"}
                      name={"requirements_val"}
                      placeholder={addTendersLang.requirements_val[lang]}
                      type={"text"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.requirements_val}
                      onBlur={handleBlur}
                      error={
                        touched.requirements_val &&
                        Boolean(errors.requirements_val)
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Inputs
                      id={"code"}
                      variant={"standard"}
                      name={"code"}
                      placeholder={addTendersLang.code[lang]}
                      type={"text"}
                      width={"100%"}
                      height={"3rem"}
                      onChange={handleChange}
                      value={values.code}
                      onBlur={handleBlur}
                      error={touched.code && Boolean(errors.code)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Inputs
                      id={"note"}
                      variant={"standard"}
                      name={"note"}
                      placeholder={addTendersLang.note[lang]}
                      multiline={true}
                      rows={4}
                      type={"textarea"}
                      onChange={handleChange}
                      value={values.note}
                      onBlur={handleBlur}
                      error={touched.note && Boolean(errors.note)}
                      helperText={touched.note && errors.note}
                      width={"100%"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                  {/* File One */}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: "50%", height: "10vh", marginBlock: 1 }}>
                      <Box
                        component={"input"}
                        type="file"
                        ref={inputFile1}
                        name="file1"
                        sx={{
                          display: "none",
                        }}
                        onChange={(event) => {
                          const previewFile1 = URL.createObjectURL(
                            event.target.files[0]
                          );
                          setPreviewFile1(previewFile1);
                          setSelectedFile1(event.target.files[0]);
                        }}
                      />
                      <Box
                        sx={{
                          minHeight: "100%",
                          maxHeight: "100%",
                          // maxHeight: "5rem",
                          width: "100%",
                          display: "flex",
                          // alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid white",
                            width: {
                              xs: "30%",
                              sm: "30%",
                              md: "30%",
                              lg: "30%",
                              xl: "30%",
                            },
                            // minHeight: "90%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            inputFile1.current.click();
                          }}
                        >
                          <AddIcon sx={{ width: "2rem", height: "2rem" }} />
                          <Typography sx={{ fontWeight: "700" }}>
                            {addTendersLang.addFile[lang]}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            width: "70%",
                            minHeight: "100%",
                          }}
                        >
                          {previewFile1 ? (
                            <Box
                              sx={{
                                position: "relative",
                                height: "100%",
                                border: "1px solid #fff",
                              }}
                            >
                              <Box
                                sx={{
                                  // maxWidth: "50px",
                                  height: "100%",
                                  // bgcolor: "#fff",
                                  fontSize: "2rem",
                                  fontWeight: "bold",
                                  color: "#e05436",

                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  variant="p"
                                  sx={{
                                    overflow: "hidden",
                                    display: "inline-block",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "100%",
                                    // height: "1.2em",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                  }}
                                >
                                  {selectedFile1.name}
                                </Box>
                              </Box>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  fontSize: "2rem",
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  // "&:hover": {
                                  //   color: "red",
                                  //   transition: "all 0.2s",
                                  // },
                                }}
                                onClick={() => {
                                  setPreviewFile1("");
                                  setSelectedFile1("");
                                  inputFile1.current.value = "";
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                border: "1px solid black",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {addTendersLang.filePreview[lang]}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  {/* File Two */}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: "50%", height: "10vh", marginBlock: 1 }}>
                      <Box
                        component={"input"}
                        type="file"
                        ref={inputFile2}
                        name="file2"
                        sx={{
                          display: "none",
                        }}
                        onChange={(event) => {
                          const previewFile2 = URL.createObjectURL(
                            event.target.files[0]
                          );
                          setPreviewFile2(previewFile2);
                          setSelectedFile2(event.target.files[0]);
                        }}
                      />
                      <Box
                        sx={{
                          minHeight: "100%",
                          maxHeight: "100%",
                          // maxHeight: "5rem",
                          width: "100%",
                          display: "flex",
                          // alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid white",
                            width: {
                              xs: "30%",
                              sm: "30%",
                              md: "30%",
                              lg: "30%",
                              xl: "30%",
                            },
                            // minHeight: "90%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            inputFile2.current.click();
                          }}
                        >
                          <AddIcon sx={{ width: "2rem", height: "2rem" }} />
                          <Typography sx={{ fontWeight: "700" }}>
                            {addTendersLang.addFile[lang]}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            width: "70%",
                            minHeight: "100%",
                          }}
                        >
                          {previewFile2 ? (
                            <Box
                              sx={{
                                position: "relative",
                                height: "100%",
                                border: "1px solid #fff",
                              }}
                            >
                              <Box
                                sx={{
                                  // maxWidth: "50px",
                                  height: "100%",
                                  // bgcolor: "#fff",
                                  fontSize: "2rem",
                                  fontWeight: "bold",
                                  color: "#e05436",

                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  variant="p"
                                  sx={{
                                    overflow: "hidden",
                                    display: "inline-block",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "100%",
                                    // height: "1.2em",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                  }}
                                >
                                  {selectedFile2.name}
                                </Box>
                              </Box>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  fontSize: "2rem",
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  // "&:hover": {
                                  //   color: "red",
                                  //   transition: "all 0.2s",
                                  // },
                                }}
                                onClick={() => {
                                  setPreviewFile2("");
                                  setSelectedFile2("");
                                  inputFile2.current.value = "";
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                border: "1px solid black",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {addTendersLang.filePreview[lang]}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  {/* File Three */}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: "50%", height: "10vh", marginBlock: 1 }}>
                      <Box
                        component={"input"}
                        type="file"
                        ref={inputFile3}
                        name="file3"
                        sx={{
                          display: "none",
                        }}
                        onChange={(event) => {
                          const previewFile3 = URL.createObjectURL(
                            event.target.files[0]
                          );
                          setPreviewFile3(previewFile3);
                          setSelectedFile3(event.target.files[0]);
                        }}
                      />
                      <Box
                        sx={{
                          minHeight: "100%",
                          maxHeight: "100%",
                          // maxHeight: "5rem",
                          width: "100%",
                          display: "flex",
                          // alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            border: "1px solid white",
                            width: {
                              xs: "30%",
                              sm: "30%",
                              md: "30%",
                              lg: "30%",
                              xl: "30%",
                            },
                            // minHeight: "90%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            inputFile3.current.click();
                          }}
                        >
                          <AddIcon sx={{ width: "2rem", height: "2rem" }} />
                          <Typography sx={{ fontWeight: "700" }}>
                            {addTendersLang.addFile[lang]}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            width: "70%",
                            minHeight: "100%",
                          }}
                        >
                          {previewFile3 ? (
                            <Box
                              sx={{
                                position: "relative",
                                height: "100%",
                                border: "1px solid #fff",
                              }}
                            >
                              <Box
                                sx={{
                                  // maxWidth: "50px",
                                  height: "100%",
                                  // bgcolor: "#fff",
                                  fontSize: "2rem",
                                  fontWeight: "bold",
                                  color: "#e05436",

                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  variant="p"
                                  sx={{
                                    overflow: "hidden",
                                    display: "inline-block",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "100%",
                                    // height: "1.2em",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                  }}
                                >
                                  {selectedFile3.name}
                                </Box>
                              </Box>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  fontSize: "2rem",
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  // "&:hover": {
                                  //   color: "red",
                                  //   transition: "all 0.2s",
                                  // },
                                }}
                                onClick={() => {
                                  setPreviewFile3("");
                                  setSelectedFile3("");
                                  inputFile3.current.value = "";
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                border: "1px solid black",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {addTendersLang.filePreview[lang]}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {tenderData.isPending ? (
              <CircularProgress />
            ) : (
              <Button
                variant="lg"
                sx={{ width: "20rem", marginBottom: "2rem" }}
                type="submit"
              >
                {global.send[lang]}
              </Button>
            )}
          </Box>
        </Stack>
        {tenderData.successAlert}
        {tenderData.failAlert}
      </Wrapper>
    </Box>
  );
};

export default pageHoc(AddSingleTenders, ALLACCESS, "/login");

export const AutocompleteField = ({
  variant = null,
  onChange,
  label = "",
  value,
  placeholder = "",
  children,
  isPending,
  onOpen = () => {},
  onClose = () => {},
  onSelect = () => {},
  onSubmit = () => {},
  onInputChange = () => {},
  data = [],
  inputvalue,
  sx = {},
  id = "",
  ...rest
}) => {
  return (
    <Autocomplete
      loading={isPending}
      loadingText="الرجاء الإنتظار..."
      options={!isPending ? data : []}
      onOpen={onOpen}
      onClose={onClose}
      onChange={onChange}
      inputValue={inputvalue}
      onInputChange={onInputChange}
      fullWidth
      onSelect={onSelect}
      onSubmit={onSubmit}
      // popupIcon={<KeyboardArrowDownIcon />}
      noOptionsText="فارغ"
      sx={sx}
      {...rest}
      renderInput={(params) => (
        <TextField
          variant={"standard"}
          {...params}
          InputLabelProps={{ shrink: true }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
